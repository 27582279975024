import createResourceId from "../utils/createResourceId";
import { sign, decode, JWT_SECRET, JWT_EXPIRES_IN } from "../utils/jwt";
import wait from "../utils/wait";
import axiosInstance from "../components/qpexpress/axios";
import toast from "react-hot-toast";
import {
  ServerURL,
  SessionTimeinMinutes,
  UserGetPermittedUrlApiMethodUrl,
  fetch_instance,
} from "./ServerConfig";
import { customerApi } from "./customerApi";
import { DateApi } from "./DateApi";
// import React, { useState, useEffect,useCallback  } from "react"
// const users = [JSON.parse(sessionStorage.getItem('user') ? (JSON.parse(sessionStorage.getItem('user') ): {}]
const users = [];

class AuthApi {
  async get_permitted_url(user_id) {
    let urls = [];
    if (!user_id) {
      return urls;
    }
    await fetch_instance(`${ServerURL}${UserGetPermittedUrlApiMethodUrl}`, {
      method: "POST",
      headers: {
        // Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        user: user_id,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        urls = data;
        return Promise.resolve(urls);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });

    return Promise.resolve(urls);
  }

  async login({ email, password }) {
    if (localStorage.getItem("Token") == "mina") {
      const tok = localStorage.getItem("userid");
    } else {
      await axiosInstance({
        method: "post",
        url: "/token/",
        data: {
          username: email,
          password: password,
        },
      }).then(async (res) => {
        // console.log(res.data.access)
        // JWT_SECRET = res.data.access;
        localStorage.setItem("Token", res.data.access);
        localStorage.setItem("userid", res.data.id);
        localStorage.setItem("username", res.data.username);
        localStorage.setItem("email", email);
        localStorage.setItem("refrsh_token", res.data.refresh);
        localStorage.setItem("first_name", res.data.first_name);
        localStorage.setItem("last_name", res.data.last_name);
        localStorage.setItem("is_staff", res.data.is_staff);
        localStorage.setItem("is_superuser", res.data.is_superuser);
        let now = DateApi.getDate();
        now.setMinutes(now.getMinutes() + SessionTimeinMinutes);
        let datestring = DateApi.getDate(now);
        localStorage.setItem("date", datestring);


        // Customer dashboard password
        let data = await customerApi.check_customer_has_password();
        let has_password = data.has_password || 0;

        localStorage.setItem("has_password", has_password);
        if (has_password) {
          localStorage.setItem("fi_lk", 10);
        } else {
          localStorage.setItem("fi_lk", 20);
        }
        let urls = await this.get_permitted_url(res.data.id);

        if (urls.length) {
          localStorage.setItem("permitted_url", urls || []);
        } else {
          localStorage.setItem("permitted_url", ["/dashboard/"]);
        }
        // alert(res.data.first_name)

        
        let user = {
          id: res.data.id,
          avatar: null,
          email: email,
          name: res.data.username,
          first_name: res.data.first_name,
          last_name: res.data.last_name,
          token: res.data.access,
          refrsh_token: res.data.refresh,
          password: password,
          is_staff: res.data.is_staff,
          is_superuser: res.data.is_superuser,
          plan: "Standard",
          has_password: has_password,
          password_locked: 1,
          permitted_url: urls || ["/dashboard/"],
        };
        users.push(user);
      });
    }

    return new Promise((resolve, reject) => {
      try {
        // Find the user

        const user = users.find((_user) => _user && _user.email === email);
        // console.log('password' , password)
        // console.log('password user' , user.password)
        // if (!user || user.password !== password) {
        //   reject(new Error("Please check your email and password"));
        //   return;
        // }

        // Create the access token
        // const accessToken =localStorage.getItem('accessToken')
        // if ( JWT_SECRET== null){
        //   let JWT_SECRET2 = localStorage.getItem('accessToken')
        //   const accessToken = sign({ userId: user.token }, JWT_SECRET2, { expiresIn: JWT_EXPIRES_IN });
        // }

        const accessToken = sign({ userId: user.token }, JWT_SECRET, {
          expiresIn: JWT_EXPIRES_IN,
        });
        localStorage.setItem("accessToken", accessToken);
        user.accessToken = accessToken;
        let user_obj = { ...user };
        delete user_obj["password"];
        localStorage.setItem("user", JSON.stringify(user_obj));
        resolve(accessToken);
      } catch (err) {
        console.error("[Auth Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }

  async register({ email, name, password }) {
    await wait(1000);

    return new Promise((resolve, reject) => {
      try {
        // Check if a user already exists
        let user = users.find((_user) => _user && _user.email === email);

        if (user) {
          reject(new Error("User already exists"));
          return;
        }

        user = {
          id: createResourceId(),
          avatar: null,
          email,
          name,
          password,
          plan: "Standard",
        };

        users.push(user);

        const accessToken = sign({ userId: user.id }, JWT_SECRET, {
          expiresIn: JWT_EXPIRES_IN,
        });

        resolve(accessToken);
      } catch (err) {
        console.error("[Auth Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }

  me(accessToken) {
    if (users.length == 0) {
      let us = JSON.parse(localStorage.getItem("user"));
      users.push(us);
    }
    return new Promise((resolve, reject) => {
      try {
        // Decode access token

        const { userId } = decode(accessToken);
        // const { userId } = localStorage.getItem("userid")

        // Find the user

        const user = users.find((_user) => _user.token === userId);

        if (!user) {
          reject(new Error("Invalid authorization token"));
          return;
        }

        const permitted_url = localStorage.getItem("permitted_url")
          ? localStorage.getItem("permitted_url").split(",")
          : ["/dashboard/"];

        const enhancedPages = [
          '/dashboard/adminorders',
          '/dashboard/shipmentrequest',
          '/dashboard/customers/revenue/',
        ];

        enhancedPages.forEach(page => {
          if(permitted_url.includes(page)){
            // 
            permitted_url.push(`${page.replace(/\/$/, '')}_enhanced`)
          }
        })

        resolve({
          id: user.id,
          avatar: user.avatar,
          email: user.email,
          name: user.name,
          first_name: user.first_name,
          last_name: user.last_name,
          plan: user.plan,
          token: user.token,
          is_staff: user.is_staff,
          is_superuser: user.is_superuser,
          has_password: parseInt(localStorage.getItem("has_password") || 0, 10),
          password_locked: 1,
          permitted_url: permitted_url,
        });
      } catch (err) {
        console.error("[Auth Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }
}

export const authApi = new AuthApi();
