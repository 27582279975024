import {
  getAccessToken,
  fetch_instance,
  ServerURL,
  GetReturnShipperOrder,
  OrderFormListMethodURL,
  NewOrderMethodURL,
  UpdateOrderMethodURL,
  GetAdminOrderListMethodURL,
  GetAdminOrderMethodURL,
  getShipmentdataMethodURL,
  getcustomerByPhoneMethodURL,
  updateDealFeesAndReturnMethodUrl,
  updateZeroShipperFeesAndReturnMethodUrl,
  updateReviewMethodUrl,
  AddOrderAlertMethodUrl,
  updateOrderInOutMethodUrl,
  updateOrderMoneyCashedMethodUrl,
  updateOrderRCMethodUrl,
  UpdateQuickActionMethodUrl,
  DeleteOrdersMethodUrl,
  UpdateOrdersStatusMethodUrl,
  CheckPhoneNumberMethodUrl,
  GetCustomerDetailsApiMethodUrl,
  OrderFormListwithoutPermessionMethodURL,
  HurryOrderAPIUrl,
  GetOrderImagesUrl,
  updateOrderShipperMoneyCashedMethodUrl,
  AddCollectOrderApiMethodUrl,
  UploadOrderPhotoMethodApiUrl,
  ChangeMaterialRequestShipper,
  ChangeCollectRequestShipper,
  ChangePickupRequestShipper,
  GetTodayShipperOrder,
  GetArchivedOrderListMethodURL,
  ArchiveOrdersMethodUrl,
  TaxableOrdersMethodUrl,
} from './ServerConfig';
import toast from 'react-hot-toast';

class OrderApi {
  async checkPhoneNumber(data) {
    let response = {};
    await fetch_instance(`${ServerURL}${CheckPhoneNumberMethodUrl}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        response = res;
      });

    return response;
  }
  async getOrders(next = null, query = null, rejected = '0') {
    let orders = [];
    let replies = [];
    //let page2=localStorage.getItem("page_no")
    // console.log(page)
    let url = `${ServerURL}addorders/order/?rejected=${rejected}`;
    if (next) {
      url = next;
    }
    if (query) {
      let url = `${ServerURL}addorders/order/?query=${query}&rejected=${rejected}`;
    }
    await fetch_instance(url, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        orders = res;
      });
    return Promise.resolve(orders);
  }

  async getCustomerPrintOrders(params) {

    let orders = []

    const queryString = new URLSearchParams(params);

    await fetch_instance(`${ServerURL}addorders/order/?${queryString.toString()}`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        orders = res;
      });

    return Promise.resolve(orders);
  }

  async getTodayOrders(next = null, query = null) {
    let orders = [];
    let replies = [];
    //let page2=localStorage.getItem("page_no")
    // console.log(page)
    let url = `${ServerURL}addorders/order/?today=1`;
    if (next) {
      url = next;
    }
    if (query) {
      let url = `${ServerURL}addorders/order/?query=${query}&today=1`;
    }
    await fetch_instance(url, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        orders = res;
      });
    return Promise.resolve(orders);
  }

  async getsearchOrders(
    searchpage = null,
    citychange = '',
    status = '',
    printO = '',
    filterDate = '',
    to_date = '',
    rejected = '0',
  ) {
    let orders = [];
    let replies = [];
    let url = `${ServerURL}addorders/order/?query=${searchpage}&city=${citychange}&status=${status}&printO=${printO}&order_date=${filterDate}&to_date=${to_date}&rejected=${rejected}`;
    await fetch_instance(url, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        orders = res;
      });
    return Promise.resolve(orders);
  }

  async searchOrderWithSerial(order_serial) {
    let orders = [];
    let url = `${ServerURL}addorders/order/?order_serial=${order_serial}`;
    await fetch_instance(url, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        orders = res;
      });
    return Promise.resolve(orders);
  }
  async searchOrderWithCustomerSerial(customer_serial) {
    let orders = [];
    let url = `${ServerURL}addorders/order/?customer_serial=${customer_serial}`;
    await fetch_instance(url, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        orders = res;
      });
    return Promise.resolve(orders);
  }

  async getCancelledOrders(next = null, query = null) {
    let orders = [];
    let url = `${ServerURL}addorders/cancelledorders`;
    if (next) {
      url = next;
    } else {
      url = `${ServerURL}addorders/cancelledorders?query=${query}&status=6`;
    }
    await fetch_instance(url, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        orders = res;
      });
    return Promise.resolve(orders);
  }
  async getsearchCancelledOrders(
    searchpage = null,
    citychange = '',
    status = '',
    printO = '',
    filterDate = '',
    to_date = '',
    rejected = '0',
  ) {
    let orders = [];
    let replies = [];
    let url = `${ServerURL}addorders/cancelledorders?query=${searchpage}&city=${citychange}&status=${status}&printO=${printO}&order_date=${filterDate}`;
    await fetch_instance(url, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        orders = res;
      });
    return Promise.resolve(orders);
  }
  async getArchivedOrders(next = null, query = null) {
    let orders = [];
    let url = `${ServerURL}addorders/cancelledorders`;
    if (next) {
      url = next;
    } else {
      url = `${ServerURL}addorders/archivedorders?query=${query}&status=7`;
    }
    await fetch_instance(url, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        orders = res;
      });
    return Promise.resolve(orders);
  }
  async getsearchArchivedOrders(
    page = null,
    limit = null,
    searchpage = null,
    citychange = '',
    status = '',
    printO = '',
    filterDate = '',
    to_date = '',
    rejected = '0',
  ) {
    let orders = [];
    let replies = [];
    let url = `${ServerURL}addorders/archivedorders?page=${page}&page_size=${limit}&query=${searchpage}&city=${citychange}&status=${status}&printO=${printO}&order_date=${filterDate}`;
    await fetch_instance(url, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        orders = res;
      });
    return Promise.resolve(orders);
  }
  async searchgetsearchOrders(
    searchpage = null,
    citychange = '',
    status = '',
    printO = '',
    filterDate = '',
  ) {
    let orders = [];
    let replies = [];
    let url = `${ServerURL}addorders/order/?query=${searchpage}&city=${citychange}&status=${status}&printO=${printO}&order_date=${filterDate}&today=1`;
    await fetch_instance(url, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        orders = res;
      });
    return Promise.resolve(orders);
  }

  async getsearchOrdersforshipping(
    searchpage = null,
    FilterDate = null,
    serial_query = '',
    customer = '',
    page = 1,
    page_size = 10,
  ) {
    // alert (FilterDate)
    let orders = [];
    let url = `${ServerURL}addorders/search_orders_requst_shipping?query=${
      searchpage || ''
    }&FilterDate=${FilterDate || ''}&serial_query=${
      serial_query || ''
    }&customer=${customer || ''}&page=${page || 1}&page_size=${page_size || 10}
    `;
    await fetch_instance(url, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        orders = res;
      });
    return Promise.resolve(orders);
  }
  async getoderforequest() {
    let orders = [];
    let url = `${ServerURL}addorders/order/?status=1&pickup=0`;
    await fetch_instance(url, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        orders = res;
      });
    return Promise.resolve(orders);
  }

  async getOrder(orderId) {
    let order = [];
    let cities = [];
    await fetch_instance(`${ServerURL}addorders/order/${orderId}/`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        order = res;
      });
    await fetch_instance(`${ServerURL}locations/city/`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((city) => {
        cities = city;
      });
    return { order: order, cities: cities };
  }

  async getCompanyStaticts() {
    let staticts = [];
    await fetch_instance(`${ServerURL}addorders/company_saticts`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        staticts = res;
      });
    return Promise.resolve(staticts);
  }
  //collect
  async getbackups(
    FilterDate,
    UserType = null,
    ShippersFilter = [],
    toDate = '',
  ) {
    let backuplist = [];
    let shippers = [];
    await fetch_instance(
      `${ServerURL}addorders/backup_list?FilterDate=${
        FilterDate || ''
      }&user_type=${UserType || ''}&shippers=${encodeURIComponent(
        JSON.stringify(ShippersFilter || []),
      )}&to_date=${toDate || ''}`,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        backuplist = res;
      });
    // console.log(backuplist)
    await fetch_instance(`${ServerURL}shipper/`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((city) => {
        shippers = city;
      });
    return { backuplist: backuplist, shippers: shippers };
    //return Promise.resolve(backuplist);
  }
  async MaterialRequestList(
    FilterDate = '',
    status = null,
    ShippersFilter = [],
    toDate = '',
  ) {
    let backuplist = [];
    let shippers = [];
    let url = `${ServerURL}addorders/material_requst_list?date=${FilterDate}&to_date=${
      toDate || ''
    }`;
    if (status != null) {
      url = `${ServerURL}addorders/material_requst_list?date=${FilterDate}&status=${status}`;
    }
    if (ShippersFilter.length > 0) {
      url += `&shippers=${encodeURIComponent(JSON.stringify(ShippersFilter))}`;
    }

    await fetch_instance(url, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        backuplist = res;
      });
    // console.log(backuplist)
    await fetch_instance(`${ServerURL}shipper/`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((city) => {
        shippers = city;
      });
    return { backuplist: backuplist, shippers: shippers };
    //return Promise.resolve(backuplist);
  }

  async getPickupHistory(
    FilterDate = null,
    UserType = null,
    ShippersFilter = [],
    toDate = '',
  ) {
    let backuplist = [];
    // let shippers=[]

    await fetch_instance(
      `${ServerURL}addorders/pickup_list_history?FilterDate=${
        FilterDate || ''
      }&user_type=${UserType || ''}&shippers=${encodeURIComponent(
        JSON.stringify(ShippersFilter || []),
      )}&to_date=${toDate || ''}`,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        backuplist = res;
      });
    // console.log(backuplist)
    // await fetch_instance(`${ServerURL}shipper/`,{
    // headers:{
    //   Authorization:`Bearer ${getAccessToken()}`
    // }
    // }).then((res) => res.json()).then((city)=>{ shippers = city })
    return backuplist;
    //return Promise.resolve(backuplist);
  }
  async getCollectionRequest(
    ShippersFilter = [],
    FilterDate = '',
    toDate = '',
    total_orders = 0,
    page = 1,
    limit = '',
    query = '',
    status_query = '',
    has_return_request = '',
  ) {
    let collect_req = [];
    await fetch_instance(
      `${ServerURL}addorders/collect?page=${page || 1}&page_size=${
        limit || ''
      }&query=${query || ''}&status_query=${
        status_query || ''
      }&has_return_request=${
        has_return_request || ''
      }&shippers=${encodeURIComponent(
        JSON.stringify(ShippersFilter || []),
      )}&FilterDate=${FilterDate || ''}&to_date=${toDate || ''}&total_orders=${
        total_orders || 0
      }`,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        collect_req = res;
      });

    return Promise.resolve(collect_req);
  }
  async getAdminOrderList(page, limit, Filterdata) {
    let order = [];

    // if (next == 1) {
    let next = `${ServerURL}${GetAdminOrderListMethodURL}?page=${page}&page_size=${limit}`;
    // }

    await fetch_instance(next, {
      method: 'POST',

      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Filterdata: Filterdata || {} }),
    })
      .then((res) => res.json())
      .then((data) => {
        order = data;
      });

    return Promise.resolve(order);
  }
  async getAdminEnhancedOrderList(page, limit, Filterdata) {
    let order = [];

    // if (next == 1) {
    let next = `${ServerURL}orders/enhanced?page=${page}&page_size=${limit}`;
    // }

    await fetch_instance(next, {
      method: 'POST',

      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Filterdata: Filterdata || {} }),
    })
      .then((response) => {
        if(!response.ok){
          throw response
        }

        return response.json()
      })
      .then((data) => {
        order = data;
      }).catch(() =>{
        order = {}
      })

    return Promise.resolve(order);
  }
  async getArchivedOrderList(page, limit, Filterdata) {
    let order = [];

    // if (next == 1) {
    let next = `${ServerURL}${GetArchivedOrderListMethodURL}?page=${page}&page_size=${limit}`;
    // }

    await fetch_instance(next, {
      method: 'POST',

      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Filterdata: Filterdata || {} }),
    })
      .then((res) => res.json())
      .then((data) => {
        order = data;
      });

    return Promise.resolve(order);
  }

  async archive_orders(obj) {
    let response1;

    await fetch_instance(`${ServerURL}${ArchiveOrdersMethodUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      response1 = response;
      return Promise.resolve(response);
    });
    return Promise.resolve(response1);
  }

  async taxable_orders(obj) {
    let response1;

    await fetch_instance(`${ServerURL}${TaxableOrdersMethodUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      response1 = response;
      return Promise.resolve(response);
    });
    return Promise.resolve(response1);
  }

  async getCity() {
    let cities = [];
    await fetch_instance(`${ServerURL}locations/city/`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((city) => {
        cities = city;
      });
    return cities;
  }
  // async getAdminOrderList(){
  //   let order = []
  //   await fetch_instance(`${ServerURL}${GetAdminOrderListMethodURL}`,{
  //     headers:{
  //       Authorization:`Bearer ${getAccessToken()}`
  //     }
  //     })
  //     .then((response) => {
  //       return response.json();
  //    })
  //    .then((data) => {

  //     order = data
  //        return Promise.resolve(order)
  //    })
  //    .catch((error)=>{
  //        toast.error(error.toString())
  // console.log(error)
  //    });
  //    return Promise.resolve(order)
  // }
  async getAdminOrder(id) {
    let order = [];
    await fetch_instance(`${ServerURL}${GetAdminOrderMethodURL}?id=${id}`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        order = data;
        return Promise.resolve(order);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(order);
  }

  async get_FromOrder_listwithoutPermession() {
    let obj = [];
    await fetch_instance(
      `${ServerURL}${OrderFormListwithoutPermessionMethodURL}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;

        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(obj);
  }

  async get_FromOrder_list(params) {
    let obj = [];

    const queryParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== '') {
        queryParams.append(key, value);
      }
    });

    await fetch_instance(`${ServerURL}${OrderFormListMethodURL}?${queryParams.toString()}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // // console.log(data)
        obj = data;
        // if (data.cities){
        //   let cities = []
        //   data.cities.forEach((e)=>{
        //     cities.push({
        //       "text":e.name ,
        //       "value":e.id ,

        //     })
        //   })
        //   data.cities = cities
        // }

        // if (data.customers){
        //   let customers = []
        //   data.customers.forEach((e)=>{
        //     customers.push({
        //       "text":e.company_name ,
        //       "value":e.id ,

        //     })
        //   })
        //   data.customers = customers
        // }

        // if (data.shippers){
        //   let shippers = []
        //   data.shippers.forEach((e)=>{
        //     shippers.push({
        //       "text":e.Shipper_name ,
        //       "value":e.id ,

        //     })
        //   })
        //   data.shippers = shippers
        // }

        // if (data.zones){
        //   let zones = []
        //   data.zones.forEach((e)=>{
        //     zones.push({
        //       "text":e.name ,
        //       "value":e.id ,

        //     })
        //   })
        //   data.zones = zones
        // }

        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(obj);
  }

  async get_FromOrder_list_enhanced() {
    let obj = [];
    await fetch_instance(`${ServerURL}orders/get_form_list_enhanced`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // // console.log(data)
        obj = data;
        // if (data.cities){
        //   let cities = []
        //   data.cities.forEach((e)=>{
        //     cities.push({
        //       "text":e.name ,
        //       "value":e.id ,

        //     })
        //   })
        //   data.cities = cities
        // }

        // if (data.customers){
        //   let customers = []
        //   data.customers.forEach((e)=>{
        //     customers.push({
        //       "text":e.company_name ,
        //       "value":e.id ,

        //     })
        //   })
        //   data.customers = customers
        // }

        // if (data.shippers){
        //   let shippers = []
        //   data.shippers.forEach((e)=>{
        //     shippers.push({
        //       "text":e.Shipper_name ,
        //       "value":e.id ,

        //     })
        //   })
        //   data.shippers = shippers
        // }

        // if (data.zones){
        //   let zones = []
        //   data.zones.forEach((e)=>{
        //     zones.push({
        //       "text":e.name ,
        //       "value":e.id ,

        //     })
        //   })
        //   data.zones = zones
        // }

        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(obj);
  }

  async new_AdminOrder(obj) {
    let result = {};
    // const d = DateApi.getDate(obj.order_date);
    // let month = d.getMonth() + 1;
    // let day = d.getDate();
    // const year = d.getFullYear();

    // if (month.length < 2) month = "0" + month;
    // if (day.length < 2) day = "0" + day;
    // obj.order_date =
    //   year +
    //   "-" +
    //   month +
    //   "-" +
    //   day +
    //   " " +
    //   d.getHours() +
    //   ":" +
    //   d.getMinutes() +
    //   ":" +
    //   d.getSeconds();

    const data = {
      order_date: obj.order_date,
      DeadLine_date: obj.order_date,
      customer: obj.customer.id,
      city: obj.city == '' ? null : obj.city.id,
      shipment_contents: obj.shipment_contents,
      full_name: obj.full_name,
      phone: obj.phone,
      address: obj.address,
      zone: obj.zone == '' ? null : obj.zone.id,
      weight: obj.weight || 0,
      total_amount: obj.total_amount || 0,
      total_fees: obj.total_fees,
      shipper_fees: obj.shipper_fees,
      shipper: obj.shipper.id,
      notes: obj.notes,
      pick_up: obj.pick_up,
      price: obj.total_amount,
      return_count: obj.return_count,
      Order_Delivery_Status: obj.Order_Delivery_Status,
      Delivery_satus_date: obj.order_date,
      swapped_serial: obj.swapped_serial,
      customer_serial: obj.customer_serial,
    };
    await fetch_instance(`${ServerURL}${NewOrderMethodURL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      result = response;
      return Promise.resolve(result);
    });
    return Promise.resolve(result);
  }

  async update_AdminOrder(obj) {
    // const d = DateApi.getDate(obj.order_date);
    // let month = d.getMonth() + 1;
    // let day = d.getDate();
    // const year = d.getFullYear();

    // if (month.length < 2) month = "0" + month;
    // if (day.length < 2) day = "0" + day;
    // obj.order_date =
    //   year +
    //   "-" +
    //   month +
    //   "-" +
    //   day +
    //   " " +
    //   d.getHours() +
    //   ":" +
    //   d.getMinutes() +
    //   ":" +
    //   d.getSeconds();
    obj.DeadLine_date = obj.order_date;
    obj.shipper = obj.shipper.id ? obj.shipper.id : '0';
    obj.zone = obj.zone.id ? obj.zone.id : '0';
    obj.city = obj.city.id ? obj.city.id : '0';
    obj.customer = obj.customer.id ? obj.customer.id : '0';
    let result = {};
    await fetch_instance(`${ServerURL}${UpdateOrderMethodURL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    })
      .then((response) => {
        result = response;
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(result);
  }

  async getShipmentdata(Cityid, Customerid) {
    let obj = [];
    await fetch_instance(
      `${ServerURL}${getShipmentdataMethodURL}?Cityid=${Cityid}&Customerid=${Customerid}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(obj);
  }

  async getcustomerByPhone(phone) {
    let obj = [];
    await fetch_instance(
      `${ServerURL}${getcustomerByPhoneMethodURL}?phone=${phone}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(obj);
  }

  async getOrderreplies(id) {
    let replies = {};
    await fetch_instance(`${ServerURL}get_order_replies/?id=${id}`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        replies = res;
      });
    return Promise.resolve(replies);
  }

  async updateZeroShipperFeesAndReturn(values) {
    let response1;

    await fetch_instance(
      `${ServerURL}${updateZeroShipperFeesAndReturnMethodUrl}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(values),
      },
    ).then((response) => {
      response1 = response;
      return Promise.resolve(response);
    });
    return Promise.resolve(response1);
  }

  async updateReview(obj) {
    let response1;

    await fetch_instance(`${ServerURL}${updateReviewMethodUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      response1 = response;
      return Promise.resolve(response);
    });
    return Promise.resolve(response1);
  }

  async updateDealFeesAndReturn(values) {
    let response1;

    await fetch_instance(`${ServerURL}${updateDealFeesAndReturnMethodUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({
        ...values,
      }),
    }).then((response) => {
      response1 = response;
      return Promise.resolve(response);
    });
    return Promise.resolve(response1);
  }

  async updateOrderInOut(obj) {
    let response1;

    await fetch_instance(`${ServerURL}${updateOrderInOutMethodUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      response1 = response;
      return Promise.resolve(response);
    });
    return Promise.resolve(response1);
  }
  async updateOrderMoneyCashed(obj) {
    let response1;

    await fetch_instance(`${ServerURL}${updateOrderMoneyCashedMethodUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      response1 = response;
      return Promise.resolve(response);
    });
    return Promise.resolve(response1);
  }
  async updateOrderShipperMoneyCashed(obj) {
    let response1;

    await fetch_instance(
      `${ServerURL}${updateOrderShipperMoneyCashedMethodUrl}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(obj),
      },
    ).then((response) => {
      response1 = response;
      return Promise.resolve(response);
    });
    return Promise.resolve(response1);
  }

  async AddOrderAlert(obj) {
    // const d = DateApi.getDate(obj.Alert_date);
    // let month = d.getMonth() + 1;
    // let day = d.getDate();
    // const year = d.getFullYear();

    // if (month.length < 2) month = "0" + month;
    // if (day.length < 2) day = "0" + day;
    // obj.Alert_date =
    //   year +
    //   "-" +
    //   month +
    //   "-" +
    //   day +
    //   " " +
    //   d.getHours() +
    //   ":" +
    //   d.getMinutes() +
    //   ":" +
    //   d.getSeconds();

    let result = {};
    await fetch_instance(`${ServerURL}${AddOrderAlertMethodUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      result = response;
      // return Promise.resolve(result)
    });
    return Promise.resolve(result);
  }

  async add_collect_order(obj) {
    let response1;

    await fetch_instance(`${ServerURL}${AddCollectOrderApiMethodUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      response1 = response;
      return Promise.resolve(response);
    });
    return Promise.resolve(response1);
  }
  async updateOrderRC(obj) {
    let response1;

    await fetch_instance(`${ServerURL}${updateOrderRCMethodUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      response1 = response;
      return Promise.resolve(response);
    });
    return Promise.resolve(response1);
  }

  async UpdateQuickAction(obj) {
    let response1;

    await fetch_instance(`${ServerURL}${UpdateQuickActionMethodUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      response1 = response;
      return Promise.resolve(response);
    });
    return Promise.resolve(response1);
  }

  async UpdateOrdersStatus(obj) {
    let response1;

    // const d = DateApi.getDate(obj.StatusDate);
    // let month = d.getMonth() + 1;
    // let day = d.getDate();
    // const year = d.getFullYear();

    // if (month.length < 2) month = "0" + month;
    // if (day.length < 2) day = "0" + day;
    // obj.StatusDate =
    //   year +
    //   "-" +
    //   month +
    //   "-" +
    //   day +
    //   " " +
    //   d.getHours() +
    //   ":" +
    //   d.getMinutes() +
    //   ":" +
    //   d.getSeconds();

    await fetch_instance(`${ServerURL}${UpdateOrdersStatusMethodUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      response1 = response;
      return Promise.resolve(response);
    });
    return Promise.resolve(response1);
  }

  async get_customer_details(query) {
    let obj = {};

    await fetch_instance(
      `${ServerURL}${GetCustomerDetailsApiMethodUrl}?query=${query}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    ).then((response) => {
      obj = response.json();
      return Promise.resolve(obj);
    });
    return Promise.resolve(obj);
  }

  async DeleteOrders(obj) {
    let response1;

    await fetch_instance(`${ServerURL}${DeleteOrdersMethodUrl}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      response1 = response;
      return Promise.resolve(response);
    });
    return Promise.resolve(response1);
  }

  async getContacts() {
    let contacts = [];
    await fetch_instance(`${ServerURL}settings/get_user_settings`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        contacts = result;
      });
    return contacts;
  }

  async SendSMS(obj) {
    let response1;
    await fetch_instance(`${ServerURL}orders/sendsms`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      response1 = response;
      return Promise.resolve(response);
    });
    return Promise.resolve(response1);
  }

  async OrderbulkUpdate(obj) {
    let response1;

    await fetch_instance(`${ServerURL}orders/OrderbulkUpdate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      response1 = response;
      return Promise.resolve(response);
    });
    return Promise.resolve(response1);
  }

  async get_defaulthub() {
    let obj = [];
    await fetch_instance(`${ServerURL}orders/get_defaulthub`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(obj);
  }
  async get_extra_expenses(fromdate, todate, shipper) {
    let obj = [];
    await fetch_instance(
      `${ServerURL}addorders/get_extra_expenses?fromdate=${fromdate}&todate=${todate}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(obj);
  }

  async getUserPermession(userid = '') {
    let contacts = [];
    await fetch_instance(
      `${ServerURL}orders/get_FieldPermissionById?userid=${userid || ''}`,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    )
      .then((res) => res.json())
      .then((result) => {
        contacts = result;
      });
    return contacts;
  }
  async hurry_order(serial) {
    let response;
    await fetch_instance(`${ServerURL}${HurryOrderAPIUrl}`, {
      method: 'POST',

      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        order: serial,
      }),
    }).then((res) => {
      response = res;
    });
    return response;
  }
  async get_hurry_order(day_date, to_date, id = '', query = '', action = '') {
    let orders = [];
    await fetch_instance(
      `${ServerURL}${HurryOrderAPIUrl}?day_date=${day_date}&to_date=${
        to_date || ''
      }&id=${id || ''}&query=${query || ''}&action=${action || ''}`,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => res.json())
      .then((result) => {
        orders = result;
        return Promise.resolve(orders);
      });
    return Promise.resolve(orders);
  }
  async update_hurry_order(id, user_id) {
    await fetch_instance(`${ServerURL}${HurryOrderAPIUrl}/${id}/`, {
      method: 'PATCH',

      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        approved: 1,
        approved_by: user_id,
      }),
    });
  }

  async getCrudPermission() {
    let contacts = [];
    await fetch_instance(`${ServerURL}orders/get_CrudPermission`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        contacts = result;
      });
    return contacts;
  }

  //follow orders
  async get_follow_order(
    day_date,
    shipper = '',
    toDate = '',
    query = '',
    followStatus = '',
    imageStatus = '',
    totalStatus = 0,
  ) {
    let orders = {};
    await fetch_instance(
      `${ServerURL}addorders/getorderfollowup?day_date=${
        day_date || ''
      }&shipper=${shipper || ''}&to_date=${toDate || ''}&query=${
        query || ''
      }&followStatus=${followStatus || ''}&imageStatus=${
        imageStatus || ''
      }&totalStatus=${totalStatus || ''}`,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          'Content-Type': 'application/json',
        },
      },
    )
      .then((res) => res.json())
      .then((result) => {
        orders = result;
      });
    return orders;
  }

  async getOrderFollowUpReport(body) {
    let orders = {};
    await fetch_instance(`${ServerURL}customization/getOrderFollowUpReport`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((result) => {
        orders = result;
      });
    return orders;
  }

  async get_follow_order_shippers() {
    let shippers = {};
    await fetch_instance(`${ServerURL}addorders/getOrderFollowShipperFilter`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((result) => {
        shippers = result;
      });
    return shippers;
  }
  async follow_order(id, user_id, serial) {
    let response = {};
    await fetch_instance(
      `${ServerURL}addorders/update_audit_log?id=${id}&user_id=${user_id}&serial=${serial}`,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          'Content-Type': 'application/json',
        },
      },
    ).then((res) => {
      response = res;
    });

    return response;
  }

  async get_hasOrderPermession(customerid, shipperid) {
    let contacts = [];
    await fetch_instance(
      `${ServerURL}orders/hasOrderPermession?Customerid=${customerid}&shipperid=${shipperid}`,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    )
      .then((res) => res.json())
      .then((result) => {
        contacts = result;
      });
    return contacts;
  }

  async getshipperListwithPermission() {
    let obj = [];
    await fetch_instance(`${ServerURL}orders/getshipperListwithPermission`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;

        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(obj);
  }
  async getshipperListwithReturnPermission() {
    let obj = [];
    await fetch_instance(
      `${ServerURL}orders/getshipperListwithReturnPermission`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(obj);
  }
  async getCustomerListwithReturnPermission() {
    let obj = [];
    await fetch_instance(
      `${ServerURL}orders/getCustomerListwithReturnPermission`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(obj);
  }

  ////////////////////// order images
  async getOrdersImages(serial, shipper) {
    let orders = [];
    let url = `${ServerURL}${GetOrderImagesUrl}?serial=${serial}&shipper=${shipper}`;
    if (shipper != null) {
      url = `${ServerURL}${GetOrderImagesUrl}?serial=${serial}`;
    }
    await fetch_instance(url, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        orders = res;
      });
    return Promise.resolve(orders);
  }

  async getColumnVisibility(userid) {
    let contacts = [];
    await fetch_instance(
      `${ServerURL}orders/get_OrderColumnVisibility?userid=${userid}`,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    )
      .then((res) => res.json())
      .then((result) => {
        contacts = result;
      });
    return contacts;
  }

  async saveOrderColumnVisibility(obj) {
    let response1;

    await fetch_instance(`${ServerURL}orders/saveOrderColumnVisibility`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      response1 = response;
      return Promise.resolve(response);
    });
    return Promise.resolve(response1);
  }

  async change_pickup_request_shipper(obj) {
    let response1;

    await fetch_instance(`${ServerURL}${ChangePickupRequestShipper}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      response1 = response;
      return Promise.resolve(response);
    });
    return Promise.resolve(response1);
  }
  async change_material_request_shipper(obj) {
    let response1;

    await fetch_instance(`${ServerURL}${ChangeMaterialRequestShipper}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      response1 = response;
      return Promise.resolve(response);
    });
    return Promise.resolve(response1);
  }
  async change_collect_request_shipper(obj) {
    let response1;

    await fetch_instance(`${ServerURL}${ChangeCollectRequestShipper}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      response1 = response;
      return Promise.resolve(response);
    });
    return Promise.resolve(response1);
  }

  async getTodaysShipperOrders(shipperid) {
    let orders = [];
    await fetch_instance(
      `${ServerURL}${GetTodayShipperOrder}?shipper_id=${shipperid}`,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        orders = res;
      });
    return Promise.resolve(orders);
  }

  async upload_order_photo(obj) {
    // delete settings.submit
    let res = {};
    let data = new FormData();
    for (const [key, value] of Object.entries(obj)) {
      data.append(key, value || '');
    }
    // settings.map((key,data) => {
    //   data.append(key,data)
    //   return key
    // })
    await fetch_instance(`${ServerURL}${UploadOrderPhotoMethodApiUrl}`, {
      method: 'POST',
      headers: {
        // 'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: data, // JSON.stringify(data)
    })
      .then((response) => {
        // console.log(response)
        res = response;
        return Promise.resolve(res);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(res);
  }
  async get_return_shipper_orders(shipperid) {
    let orders = [];
    await fetch_instance(
      `${ServerURL}${GetReturnShipperOrder}?shipper_id=${shipperid}`,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        orders = res;
      });
    return Promise.resolve(orders);
  }
}

export const orderApi = new OrderApi();
