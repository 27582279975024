import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  Divider,
  Link,
  Toolbar,
  Button,
  Tooltip,
  IconButton,
  Drawer,
} from "@material-ui/core";
// import Logo from '../Logo';
import User from "../../icons/User";
import MenuIcon from "../../icons/Menu";
import { StyleSheet } from "@react-pdf/renderer";
import WhatsappDrawer from "./WhatsappDrawer";
import {
  ServerURL,
  LogoHeight,
  LogoWidth,
} from "../../__fakeApi__/ServerConfig";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// const styles = StyleSheet.create({
//   linkstyle: {
//     // maxWidth: "25%",
//     width:'max-content',
//     alignSelf:'flex-start',
//     fontWeight: "bold",
//     // marginRight:'2% !important',
//     margin: 0,
//     padding: 0,
//     transition: "0.4",
//     // fontWeight: "fontWeightBold",
//     // margin:'auto',
//     // marginLeft:'5%',,
//     "&:hover": {
//       backgroundColor: "#623e80",
//       color: "white",
//       opacity: 10,
//       transition: 0.5,
//     },
//   },
// });
const linkstyle = {
  // maxWidth: "100%",
  width: "max-content",
  textDecoration: "none",
  fontWeight: "bold",
  // marginRight:'2% !important',
  marginLeft: 1,
  padding: 1,
  // transition: "0.4",
  fontSize: 15,
  // content:"width=device-width, initial-scale=1.0",
  // fontWeight: "fontWeightBold",
  // margin:'auto',
  // marginLeft:'5%',,
  "&:hover": {
    backgroundColor: "#623e80",
    color: "white",
    opacity: 10,
    transition: "0.4s",
    borderRadius: 0.75,
    borderColor: "white",
  },
};
const btn_style = {
  backgroundColor: "#624e80",
  color: "white",
  // height: "50px",
  // width: "100px",
  width: "max-content",

  marginLeft: 3,
  "&:hover": {
    backgroundColor: "#fff",
    color: "#623e80",
    opacity: 10,
    transition: 0.5,
  },
};
// const link_style = {
//   backgroundColor: "white",
//   color: "black",
//   marginRight: "2%",
//   "&:hover": {
//     textdecoration: "underline",
//     transition: 0.5,
//   },
// };

const PricingHeaderMobile = (props) => {
  const location = useLocation();
  // const { contact ,  } = props;
  const { onMobileClose, openMobile, contact } = props;
  let logo = "/static/contact/qplogo.png";
  // console.log("location", location.pathname == "/" ? "#000" : "textSecondary");
  if (contact && contact.logo) {
    logo = `${ServerURL.substring(0, ServerURL.length - 1)}${contact.logo}`;
  }

  // // alert(lgUp)
  // if (!lgUp) {
  //   return (
  //     <AppBar
  //       elevation={6}
  //       sx={{ backgroundColor: "#fff", p: 2 }}
  //       {...props}
  //     >
  //       <Toolbar>
  //         <Tooltip title="">
  //           <RouterLink to="/">
  //             <img
  //               // className="image2"
  //               alt="hello"
  //               src={logo}
  //               // width="110"
  //               // style={{ width: "110" }}
  //             width={LogoWidth}
  //             height={LogoHeight}
  //             />
  //           </RouterLink>
  //         </Tooltip>

  //         <Box sx={{ flexGrow: 1 }} />
  //         <Box
  //           sx={{
  //             alignItems: "center",
  //             display: "flex",
  //             justifyContent: "center",
  //             p: 1,
  //             m: "auto",
  //             // mt: 10,
  //           }}
  //         >
  //           <Link
  //             // className="linkstyle"
  //             // style={styles.linkstyle}
  //             sx={linkstyle}
  //             component={RouterLink}
  //             color={location.pathname == "/" ? "#000" : "textSecondary"}
  //             to="/"
  //             underline="none"
  //             variant="body1"
  //             // sx={{ mx: 2, mr: 2,  }}
  //             // style={styles.linkstyle}
  //             // sx={{ mr: 2 }}
  //           >
  //             Home
  //           </Link>
  //         </Box>
  //         <Box
  //           sx={{
  //             // alignItems: "center",
  //             display: "flex",
  //             // justifyContent: "center",
  //             // p: 5,
  //             m: "auto",
  //             // mt: 10,
  //           }}
  //         >
  //           <Link
  //             color={
  //               location.pathname == "/about" ? "#000" : "textSecondary"
  //             }
  //             component={RouterLink}
  //             to="/about"
  //             underline="none"
  //             sx={linkstyle}
  //             // sx={{
  //             //   mx: 2,
  //             //   mr: 2,
  //             //   // fontWeight: "fontWeightBold",
  //             // }}
  //             variant="body1"
  //             // style={styles.linkstyle}
  //           >
  //             About US
  //           </Link>
  //         </Box>
  //         <Box
  //           sx={{
  //             // alignItems: "center",
  //             display: "flex",
  //             // justifyContent: "center",
  //             // p: 5,
  //             m: "auto",
  //             // mt: 10,
  //           }}
  //         >
  //           <Link
  //             color={
  //               location.pathname == "/service" ? "#000" : "textSecondary"
  //             }
  //             component={RouterLink}
  //             to="/service"
  //             underline="none"
  //             variant="body1"
  //             sx={linkstyle}

  //             // sx={{ mr: 1, fontWeight: "fontWeightBold" }}
  //             // style={styles.linkstyle}
  //           >
  //             Services
  //           </Link>
  //         </Box>
  //         <Box
  //           sx={{
  //             // alignItems: "center",
  //             display: "flex",
  //             // justifyContent: "center",
  //             // p: 5,
  //             m: "auto",
  //             // mt: 10,
  //           }}
  //         >
  //           <Link
  //             color="textSecondary"
  //             component={RouterLink}
  //             to="/contact"
  //             underline="none"
  //             variant="body1"
  //             // sx={{ mr: 2, fontWeight: "fontWeightBold" }}
  //             sx={linkstyle}
  //           >
  //             Find Us
  //           </Link>
  //         </Box>
  //         <Box
  //           sx={{
  //             // alignItems: "center",
  //             display: "flex",
  //             // justifyContent: "center",
  //             // p: 5,
  //             m: "auto",
  //             // mt: 10,
  //           }}
  //         >
  //           <Link
  //             color={
  //               location.pathname == "/pricing" ? "#000" : "textSecondary"
  //             }
  //             component={RouterLink}
  //             to="/pricing"
  //             underline="none"
  //             variant="body1"
  //             // sx={{ fontWeight: "fontWeightBold" }}
  //             sx={linkstyle}
  //           >
  //             Price Offers
  //           </Link>
  //         </Box>
  //         <Box
  //           sx={{
  //             // alignItems: "center",
  //             display: "flex",
  //             // justifyContent: "center",
  //             // p: 5,
  //             m: "auto",
  //             // mt: 10,
  //           }}
  //         >
  //           {location.pathname != "/login" ? (
  //             <RouterLink style={{ textDecoration: "none" }} to="/">
  //               <Button
  //                 sx={btn_style}
  //                 component={RouterLink}
  //                 size="small"
  //                 startIcon={<User fontSize="small" />}
  //                 to="/login"
  //                 variant="body1"
  //               >
  //                 Login
  //               </Button>
  //             </RouterLink>
  //           ) : (
  //             <Link
  //               color={
  //                 location.pathname == "/login" ? "#000" : "textSecondary"
  //               }
  //               // component={RouterLink}
  //               // to="/pricing"
  //               underline="none"
  //               variant="body1"
  //               startIcon={<User fontSize="small" />}
  //               // sx={{ ml: -8, fontWeight: "fontWeightBold" }}
  //               sx={linkstyle}
  //               // style={styles.linkstyle}
  //             >
  //               Login
  //             </Link>
  //           )}
  //         </Box>
  //       </Toolbar>
  //       {/* <Divider /> */}
  //     </AppBar>
  //   );
  // }
  // return (
  //   <AppBar
  //     elevation={6}
  //     sx={{ backgroundColor: "#fff", p: 2 }}
  //     {...props}
  //   >
  //     <Toolbar>
  //       <Tooltip title="">
  //         <RouterLink to="/">
  //           <img
  //             // className="image2"
  //             alt="hello"
  //             src={logo}
  //             // width="110"
  //             // style={{ width: "110" }}
  //           width={LogoWidth}
  //           height={LogoHeight}
  //           />
  //         </RouterLink>
  //       </Tooltip>

  //       <Box sx={{ flexGrow: 1 }} />
  //       <Box
  //         sx={{
  //           alignItems: "center",
  //           display: "flex",
  //           justifyContent: "center",
  //           p: 1,
  //           m: "auto",
  //           // mt: 10,
  //         }}
  //       >
  //         <Link
  //           // className="linkstyle"
  //           // style={styles.linkstyle}
  //           sx={linkstyle}
  //           component={RouterLink}
  //           color={location.pathname == "/" ? "#000" : "textSecondary"}
  //           to="/"
  //           underline="none"
  //           variant="body1"
  //           // sx={{ mx: 2, mr: 2,  }}
  //           // style={styles.linkstyle}
  //           // sx={{ mr: 2 }}
  //         >
  //           Home
  //         </Link>
  //       </Box>
  //       <Box
  //         sx={{
  //           // alignItems: "center",
  //           display: "flex",
  //           // justifyContent: "center",
  //           // p: 5,
  //           m: "auto",
  //           // mt: 10,
  //         }}
  //       >
  //         <Link
  //           color={
  //             location.pathname == "/about" ? "#000" : "textSecondary"
  //           }
  //           component={RouterLink}
  //           to="/about"
  //           underline="none"
  //           sx={linkstyle}
  //           // sx={{
  //           //   mx: 2,
  //           //   mr: 2,
  //           //   // fontWeight: "fontWeightBold",
  //           // }}
  //           variant="body1"
  //           // style={styles.linkstyle}
  //         >
  //           About US
  //         </Link>
  //       </Box>
  //       <Box
  //         sx={{
  //           // alignItems: "center",
  //           display: "flex",
  //           // justifyContent: "center",
  //           // p: 5,
  //           m: "auto",
  //           // mt: 10,
  //         }}
  //       >
  //         <Link
  //           color={
  //             location.pathname == "/service" ? "#000" : "textSecondary"
  //           }
  //           component={RouterLink}
  //           to="/service"
  //           underline="none"
  //           variant="body1"
  //           sx={linkstyle}

  //           // sx={{ mr: 1, fontWeight: "fontWeightBold" }}
  //           // style={styles.linkstyle}
  //         >
  //           Services
  //         </Link>
  //       </Box>
  //       <Box
  //         sx={{
  //           // alignItems: "center",
  //           display: "flex",
  //           // justifyContent: "center",
  //           // p: 5,
  //           m: "auto",
  //           // mt: 10,
  //         }}
  //       >
  //         <Link
  //           color="textSecondary"
  //           component={RouterLink}
  //           to="/contact"
  //           underline="none"
  //           variant="body1"
  //           // sx={{ mr: 2, fontWeight: "fontWeightBold" }}
  //           sx={linkstyle}
  //         >
  //           Find Us
  //         </Link>
  //       </Box>
  //       <Box
  //         sx={{
  //           // alignItems: "center",
  //           display: "flex",
  //           // justifyContent: "center",
  //           // p: 5,
  //           m: "auto",
  //           // mt: 10,
  //         }}
  //       >
  //         <Link
  //           color={
  //             location.pathname == "/pricing" ? "#000" : "textSecondary"
  //           }
  //           component={RouterLink}
  //           to="/pricing"
  //           underline="none"
  //           variant="body1"
  //           // sx={{ fontWeight: "fontWeightBold" }}
  //           sx={linkstyle}
  //         >
  //           Price Offers
  //         </Link>
  //       </Box>
  //       <Box
  //         sx={{
  //           // alignItems: "center",
  //           display: "flex",
  //           // justifyContent: "center",
  //           // p: 5,
  //           m: "auto",
  //           // mt: 10,
  //         }}
  //       >
  //         {location.pathname != "/login" ? (
  //           <RouterLink style={{ textDecoration: "none" }} to="/">
  //             <Button
  //               sx={btn_style}
  //               component={RouterLink}
  //               size="small"
  //               startIcon={<User fontSize="small" />}
  //               to="/login"
  //               variant="body1"
  //             >
  //               Login
  //             </Button>
  //           </RouterLink>
  //         ) : (
  //           <Link
  //             color={
  //               location.pathname == "/login" ? "#000" : "textSecondary"
  //             }
  //             // component={RouterLink}
  //             // to="/pricing"
  //             underline="none"
  //             variant="body1"
  //             startIcon={<User fontSize="small" />}
  //             // sx={{ ml: -8, fontWeight: "fontWeightBold" }}
  //             sx={linkstyle}
  //             // style={styles.linkstyle}
  //           >
  //             Login
  //           </Link>
  //         )}
  //       </Box>
  //     </Toolbar>
  //     {/* <Divider /> */}
  //   </AppBar>
  // );

  const content = (
    <AppBar elevation={6} sx={{ backgroundColor: "#fff", p: 2 }} {...props}>
      <Toolbar>
        <Tooltip title="">
          <RouterLink to="/">
            <img
              // className="image2"
              alt="hello"
              src={logo}
              // width="110"
              // style={{ width: "110" }}
              width={LogoWidth}
              height={LogoHeight}
            />
          </RouterLink>
        </Tooltip>

        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            p: 1,
            m: "auto",
            // mt: 10,
          }}
        >
          <Link
            // className="linkstyle"
            // style={styles.linkstyle}
            sx={linkstyle}
            component={RouterLink}
            color={location.pathname == "/" ? "#000" : "textSecondary"}
            to="/"
            underline="none"
            variant="body1"
            // sx={{ mx: 2, mr: 2,  }}
            // style={styles.linkstyle}
            // sx={{ mr: 2 }}
          >
            Home
          </Link>
        </Box>
        <Box
          sx={{
            // alignItems: "center",
            display: "flex",
            // justifyContent: "center",
            // p: 5,
            m: "auto",
            // mt: 10,
          }}
        >
          <Link
            color={location.pathname == "/about" ? "#000" : "textSecondary"}
            component={RouterLink}
            to="/about"
            underline="none"
            sx={linkstyle}
            // sx={{
            //   mx: 2,
            //   mr: 2,
            //   // fontWeight: "fontWeightBold",
            // }}
            variant="body1"
            // style={styles.linkstyle}
          >
            About US
          </Link>
        </Box>
        <Box
          sx={{
            // alignItems: "center",
            display: "flex",
            // justifyContent: "center",
            // p: 5,
            m: "auto",
            // mt: 10,
          }}
        >
          <Link
            color={location.pathname == "/service" ? "#000" : "textSecondary"}
            component={RouterLink}
            to="/service"
            underline="none"
            variant="body1"
            sx={linkstyle}

            // sx={{ mr: 1, fontWeight: "fontWeightBold" }}
            // style={styles.linkstyle}
          >
            Services
          </Link>
        </Box>
        <Box
          sx={{
            // alignItems: "center",
            display: "flex",
            // justifyContent: "center",
            // p: 5,
            m: "auto",
            // mt: 10,
          }}
        >
          <Link
            color="textSecondary"
            component={RouterLink}
            to="/contact"
            underline="none"
            variant="body1"
            // sx={{ mr: 2, fontWeight: "fontWeightBold" }}
            sx={linkstyle}
          >
            Find Us
          </Link>
        </Box>
        <Box
          sx={{
            // alignItems: "center",
            display: "flex",
            // justifyContent: "center",
            // p: 5,
            m: "auto",
            // mt: 10,
          }}
        >
          <Link
            color={location.pathname == "/pricing" ? "#000" : "textSecondary"}
            component={RouterLink}
            to="/pricing"
            underline="none"
            variant="body1"
            // sx={{ fontWeight: "fontWeightBold" }}
            sx={linkstyle}
          >
            Price Offers
          </Link>
        </Box>
        <Box
          sx={{
            // alignItems: "center",
            display: "flex",
            // justifyContent: "center",
            // p: 5,
            m: "auto",
            // mt: 10,
          }}
        >
          {location.pathname != "/login" ? (
            <RouterLink style={{ textDecoration: "none" }} to="/">
              <Button
                sx={btn_style}
                component={RouterLink}
                size="small"
                startIcon={<User fontSize="small" />}
                to="/login"
                variant="body1"
              >
                Login
              </Button>
            </RouterLink>
          ) : (
            <Link
              color={location.pathname == "/login" ? "#000" : "textSecondary"}
              // component={RouterLink}
              // to="/pricing"
              underline="none"
              variant="body1"
              startIcon={<User fontSize="small" />}
              // sx={{ ml: -8, fontWeight: "fontWeightBold" }}
              sx={linkstyle}
              // style={styles.linkstyle}
            >
              Login
            </Link>
          )}
        </Box>
      </Toolbar>
      {/* <Divider /> */}
    </AppBar>
  );
  return (
    <Drawer
      anchor="left"
      onClose={()=>onMobileClose(false)}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "#000",
          width: 280,
        },
      }}
      variant="temporary"
    >
      HHHHHHHHHHHHHHHHHHHHHHHHH
    </Drawer>
  );
};
// };

export default PricingHeaderMobile;
