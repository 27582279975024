// Basic Server Config
import useAuth from '../hooks/useAuth';

require('dotenv').config();

export const ServerURL = process.env.REACT_APP_SERVER_URL;
export const SocketHost = process.env.REACT_APP_SocketHost;
// export const ServerURL = "http://192.168.1.240:8000/";
// export const ServerURL = "https://www.qpexpress.online:8001/";
export const TIMEZONE = process.env.REACT_APP_TIMEZONE;

// HangonReason Configs
export const SessionTimeinMinutes = 10;
export const LogoWidth = '120';
export const LogoHeight = '20';
export const DashboardImageWidth = '400px';
// export const GoogleApiKey = "AIzaSyBUILBxCa5yyQZawAAOpD6HII48R3haimM";
// export const GoogleApiKey = "AIzaSyBsMd6G6Ou5EV9Nbm_J2XD-vekjpPggyyc";
export const GoogleApiKey = 'AIzaSyAd-IZBGOHt-CwQWCm69jztNaZmhUGYfuc';

// City Configs

export const CityMethodURL = 'locations/city/';
export const GetCityMethodURL = 'locations/city/get_city';
export const UpdateCityMethodURL = 'locations/city/update_city';
export const DeleteCityMethodURL = 'locations/city/delete_city';
export const DeleteAreaMethodURL = 'locations/area/delete_area';

export const HangonReasonMethodURL = 'HangonReason/';
export const GetHangonReasonMethodURL = 'HangonReason/get_HangonReason';
export const UpdateHangonReasonMethodURL = 'HangonReason/update_HangonReason';
export const DeleteHangonReasonMethodURL = 'HangonReason/delete_HangonReason';

// Pricing Api

export const GetPriceListMethodURL = 'settings/get_price_list';
export const GetPriceListWithIDMethodURL = 'settings/get_price';
export const NewPriceListMethodURL = 'settings/new_price_list';
export const EditPriceListMethodURL = 'settings/Edit_price_list';
export const DeletePriceListMethodURL = 'settings/delete_price_list';

// Site Settings API

export const GetSettingsMethodURL = 'settings/get_user_settings';
export const UpdateSettingsMethodURL = 'settings/edit_user_settings';

// customer category
export const CustomerCategory = 'customers/customer_category/';
export const CustomerCategoryFilter = 'customers/get_customer_category_filters';

// Zone Apis

export const ZoneMethodURL = 'locations/zone/';
export const GetZoneMethodURL = 'locations/zone/get_zone';
export const UpdateZoneMethodURL = 'locations/zone/update_zone';
export const DeleteZoneMethodURL = 'locations/zone/delete_zone';

// Shipper Apis

export const ShipperMethodURL = 'shipper/';
export const CreateShipperMethodURL = 'shipper/list';
// export const getShipperMethodURL = "shipper/list";
export const GetShipperMethodURL = 'shipper/get_shipper';
export const UpdateShipperMethodURL = 'shipper/update_shipper';
export const UpdateShipperToShipperMethodURL =
  'customization/change_shipper_data_to_shipper';

export const DeleteShipperMethodURL = 'shipper/delete_shipper';
export const getShipperForDropDownListMethodURL =
  'shipper/getShipperForDropDownList';
export const ShipperLocationMethodURL = 'shipperapp/shipper_tracking';
export const ShipperFormListMethodURL = 'shipper/get_form_list';
export const DailySendWhatsappShipperMethodURL = 'customization/daily_send_orders_to_shipper';


// Shipper Closing

export const getUncollectedShipperOrders = 'orders/getUncollectedShipperOrders';
export const CreateShipperClosingApi = 'orders/create_shipper_closing';
export const GetShipperClosingApi = 'orders/get_shipper_closing';
export const GetShipperClosingDetailsApi = 'orders/get_shipper_closing_details';

export const getShipperClosingRequestApiMethods =
  'customization/get_shipper_closing_requests';
export const getShipperClosingRequestDetailsApiMethods =
  'customization/get_shipper_closing_request_details';
export const getClosingRequestReportDetailsApiMethods =
  'customization/getClosingRequestReportDetails';

// Extra Expenses
export const ExpensesMethodUrl = 'orders/expenses/';
export const getExtraExpensesMethodUrl = 'orders/expenses/getExtraExpenses';
export const getExtraExpensesReportMethodUrl =
  'customization/getExpensesReport';

export const getExtraExpensesReportFilterMethodUrl =
  'customization/getExpensesReportFilters';

export const getClosingRequestReportMethodUrl =
  'customization/getClosingRequestReport';
export const getClosingRequestReportFilterMethodUrl =
  'customization/getClosingRequestReportFilters';

export const getShipperOrderReportFilterMethodUrl =
  'customization/getShipperCustodyReportFilters';

export const getShipperOrderReportMethodUrl =
  'customization/getShipperOrderReport';

export const getExtraExpensesShipperMethodUrl =
  'orders/expenses/getExtraExpensesForShipper';

// order API
export const UpdateOrderToDeliverdMethodUrl =
  'orders/update_order_status_to_deliverd';
export const GetOrderDeliverStatusMethodUrl =
  'customization/get_order_status_list';
export const DeleteOrderDeliverStatusMethodUrl =
  'customization/delete_order_status';
export const GetTodaysOrderStatusMethodUrl = 'customization/get_todays_orders';
//added by shymaa
export const UpdateOrderShipperMethodUrl = 'orders/update_Ordershipper';

//shaymaa code

export const OrderFormListwithoutPermessionMethodURL =
  'orders/get_form_listwithoutPermession';
export const OrderFormListMethodURL = 'orders/get_form_list';
export const NewOrderMethodURL = 'order/create_order';
export const UpdateOrderMethodURL = 'orders/update_AdminOrder';
export const GetAdminOrderListMethodURL = 'orders/';
export const GetArchivedOrderListMethodURL = 'orders/archivedorders';
export const GetAdminOrderMethodURL = 'orders/get_AdminOrder';
export const getShipmentdataMethodURL = 'ShipmentInfoByCityandCustomer/';
export const getcustomerByPhoneMethodURL = 'orders/getcustomerByPhone/';

export const GetOrderHistoryMethodUrl = 'customization/get_order_history';
export const ArchiveOrdersMethodUrl = 'customization/archive_orders';
export const TaxableOrdersMethodUrl = 'customization/taxable_orders';

export const GetOrderShipperHistoryMethodUrl =
  'customization/get_order_shipper_history_list';

// Shipper Evaluation

export const ShipperEvaluationApiMethodUrl =
  'customization/shipper_evaluation/';
export const ShipperEvaluationReportApiMethodUrl =
  'customization/Shipper_evaluation_report';

// User Evaluation
export const GetUsersUserEvaluationApiMethodUrl =
  'customization/getAvalibaleUserEvaluationForUser';
export const PostUSerEvaluationReportApiMethodUrl =
  'customization/evaluate_user';

//added by shymaa

export const updateZeroShipperFeesAndReturnMethodUrl =
  'orders/update_ZeroShipperFeesAndReturn';
export const updateDealFeesAndReturnMethodUrl =
  'orders/update_DealFeesAndReturn';
export const updateReviewMethodUrl = 'orders/updateReview';
export const updateOrderInOutMethodUrl = 'orders/updateOrderInOut';
export const updateOrderMoneyCashedMethodUrl = 'orders/updateOrderMoneyCashed';
export const updateOrderShipperMoneyCashedMethodUrl =
  'orders/updateOrderShipperMoneyCashed';
export const AddOrderAlertMethodUrl = 'orders/AddOrderAlert';
export const updateOrderRCMethodUrl = 'orders/updateOrderRC';
export const UpdateQuickActionMethodUrl = 'orders/UpdateQuickAction';
export const UpdateOrdersStatusMethodUrl = 'orders/UpdateOrdersStatus';
export const DeleteOrdersMethodUrl = 'orders/delete_Orders';

//order
export const OrderBulkDelete = 'addorders/order_bulk_delete';

export const CheckPhoneNumberMethodUrl = 'customization/checkPhoneNumber';

// Customer Notifications
export const getCustomerNotificationsApiMethodUrl =
  'notification/get_customer_notifications';
export const MarkCustomerAsReadApiMethodUrl =
  'notification/mark_customer_notifications_read';
export const MarkCustomerAllAsReadApiMethodUrl =
  'notification/mark_customer_notifications_all_read';
export const CreateCustomersNotificationsMethodURL =
  'notification/create_customer_notifications';

// Notifications

export const MarkAllAsReadApiMethodUrl = 'notification/mark_all_as_read';
export const MarkAllRepeatedOrderAsReadApiMethodUrl =
  'notification/mark_allRepeatedOrder_as_read';
export const MarkAsReadApiMethodUrl = 'notification/mark_as_read';
export const getNotificationListMethodURL = 'notification/getNotificationList';
export const getOrderAlertListMethodURL = 'notification/getOrderAlertList';
export const getRepeatedOrdersListMethodURL =
  'notification/getRepeatedOrdersList';

// Top Companies Order Report

export const TopCompaniesByOrderReportApiMethodUrl =
  'customization/get_company_orders_count';
export const CompaniesOrderByShipperReportApiMethodUrl =
  'customization/get_company_orders_count_by_shipper';
export const CompaniesOrderByCityReportApiMethodUrl =
  'customization/get_company_orders_count_by_city';

export const ExportToExcelApiMethodUrl = 'customization/export_to_excel';
export const ExportDashboardToExcelApiMethodUrl =
  'customization/get_dashboardExcel';
export const TopCompanyReportFiltersApiMethodUrl =
  'customization/getCompanyReportFilterList';
export const GetCompanyOrdersToUpdateApiMethodUrl =
  'customization/getCustomerOrdersToUpdate';

export const getCustomersFilterListApiMethodUrl =
  'customization/getCustomersFilterList';
// shipper Revenue Report
export const ShipperRevenueApiMethodUrl =
  'customization/shipper_revenue_report';
export const ShipperRevenueDetailApiMethodUrl =
  'customization/get_shipper_revenue_details';

// New shipper Revenue Report
export const NewShipperRevenueApiMethodUrl =
  'customization/shipper_revenue_report_new';
export const NewShipperRevenueDetailApiMethodUrl =
  'customization/get_shipper_revenue_details_new';

export const GetCustomerDetailsApiMethodUrl = 'customization/serach_by_phone';

// Customer Revenue Report
export const CustomerRevenueApiMethodUrl =
  'customization/customer_revenue_report';
export const CustomerRevenueDetailApiMethodUrl =
  'customization/get_customer_revenue_details';
export const CustomerRevenueSearchApiMethodUrl =
  'customization/get_customer_revenue_details_search';
export const CustomerRevenueTotalPrintDetailApiMethodUrl =
  'customization/get_customer_revenue_totals_details';

// Customer Revenue Report
export const NewCustomerRevenueApiMethodUrl =
  'customization/customer_revenue_report_new';
export const NewCustomerRevenueDetailApiMethodUrl =
  'customization/get_customer_revenue_details_new';
export const NewCustomerRevenueSearchApiMethodUrl =
  'customization/get_customer_revenue_details_search_new';
export const NewCustomerRevenueTotalPrintDetailApiMethodUrl =
  'customization/get_customer_revenue_totals_details_new';

export const GetMoneyCashedOrdersApiMethodUrl =
  'customization/get_orders_money_cashed';
export const GetShipperMoneyCashedOrdersApiMethodUrl =
  'customization/get_orders_shipper_money_cashed';

export const UpdateOrdersCustomerRevenueDetailApiMethodUrl =
  'customization/update_orders_money_cashed';
export const UpdateOrdersShipperRevenueDetailApiMethodUrl =
  'customization/update_orders_Shipper_money_cashed';

export const AddCollectOrderApiMethodUrl = 'customization/add_collect_order';
export const UploadOrderPhotoMethodApiUrl = 'shipperapp/order_pictures';

export const getShipperListCustomerRevenueDetailApiMethodUrl =
  'customization/get_shipper_list';
export const GoMoneyCustomerRevenueDetailApiMethodUrl =
  'customization/go_money';
export const AddPickupCustomerRevenueDetailApiMethodUrl =
  'customization/request_order';

// shipper_status_follow_report

export const ShipperStatusFollowFormApiMethodUrl =
  'customization/shipper_status_follow_report_form';
export const ShipperStatusFollowApiMethodUrl =
  'customization/shipper_status_follow_report';

/////////////////

// Customer Collect Deliver
export const CustomerCollectDelieverdApiMethodUrl =
  'customization/customer_collect_Deliverd';
export const CustomerCollectDelieverdBulkApiMethodUrl =
  'customization/customer_collect_Deliverd_bulk';

// customer retur undeliverd
export const CustomerReturnUnDelieverdApiMethodUrl =
  'customization/customer_return_unDeliverd';

// Operation Create Pickup Request
export const CustomerRequestPickupApiMethodUrl = 'customization/request_backup';

// Configuration Screen
export const ConfigurationApiMethodUrl = 'configuration/1/';

// Update Order Request

export const UpdateOrderRequestApiMethodUrl = 'customization/update_request/';
export const BulkDeleteOrderRequestApiMethodUrl =
  'customization/update_request/bulk_delete_request';
export const BulkUpdateOrderRequestApiMethodUrl =
  'customization/update_request/bulk_change_request_status';

// Custom Permission.

export const UserCustomPermissionApiMethodUrl =
  'custompermissions/userpermission/';
export const SaveOrderPermissionApiMethodUrl =
  'custompermissions/save_order_permission';

export const FormDataUserCustomPermissionApiMethodUrl =
  'custompermissions/get_form_data';
export const BulkDeleteUserCustomPermissionApiMethodUrl =
  'custompermissions/bulk_delete';

export const UserGetPermittedUrlApiMethodUrl =
  'custompermissions/get_permitted_url';
export const UserGetPermittedActionApiMethodUrl =
  'custompermissions/user_has_permission';

// Dashboard

export const getTop10CompanyOrdersApiMethodUrl =
  'customization/get_top_company_orders';
export const getTop10CitiesOrdersApiMethodUrl =
  'customization/get_top_city_orders';
export const getOrdersCountApiMethodUrl = 'customization/get_order_counts';
export const getopenIssueCountApiMethodUrl = 'customization/get_issues_counts';
export const getTodaysOrderCountApiMethodUrl =
  'customization/get_todays_orders_counts';
export const getTopLessShipperEvaluationApiMethodUrl =
  'customization/get_top_less_shipperEvaluations';
export const getTopLessShipperRevenueApiMethodUrl =
  'customization/top_less_shipper_revenue';
export const getTopLessCustomerRevenueApiMethodUrl =
  'customization/top_less_customer_revenue';
export const UserResetMethodAPIUrl = 'custompermissions/reset_password';
export const CustomerResetMethodAPIUrl =
  'custompermissions/customer_reset_password';
export const ShipperResetMethodAPIUrl =
  'custompermissions/shipper_reset_password';

export const FormDataUserShipperPermissionMethodAPIUrl =
  'custompermissions/ShipperUserFormData';
export const GetUserShipperPermissionMethodAPIUrl =
  'custompermissions/getUserShipperPermission';
export const SaveUserShipperPermissionMethodAPIUrl =
  'custompermissions/saveUserShipperPermission';
export const SaveUseruserPermissionMethodAPIUrl =
  'custompermissions/saveUserPermission';
//user evaluations
export const GetUserPermissionMethodAPIUrl = 'custompermissions/UserFormData';
// Hiolds Order Sms

export const SMSOrdersApiMethodUrl = 'customization/get_sms_report';
export const GetSMSHoldsOrdersApiMethodUrl =
  'customization/get_holds_orders_with_sms';
export const SentSMSHoldsOrdersApiMethodUrl =
  'customization/sent_hold_order_sms';
export const SentSMSFlollowOrdersApiMethodUrl =
  'customization/sent_follow_order_sms';

export const getShipperListSMSHoldsOrdersApiMethodUrl =
  'customization/get_shipper_list';
export const getCustomerFilterListApiMethodUrl =
  'customization/getCompanyFilterList';
export const ShipmentInfoBulkDeleteApiMethodUrl =
  'customization/bulk_delete_shipment_info';

// images url
export const GetOrderImagesUrl = 'shipperapp/order_pictures';

// images url
export const CustomerCollectAttachmentUrl =
  'shipperapp/customer_collect_attachment';
// hury order
export const HurryOrderAPIUrl = 'addorders/fastorderlist';
export const getCustomerCollectTodayMethodURL =
  'addorders/getCustomerCollectToday';

///follow up
export const followUpGet = 'custompermissions/getfollowuppermission';
export const SaveFollowUp = 'custompermissions/saveFollowUpPermission';

// Notification Permission
export const UserNotificationPermissionApiMethodUrl =
  'notification/userpermission/';

export const CreateUserMethodAPIUrl = 'customization/create_user';

///permissions
export const CityCrudPermissions = 'addorders/city_crudPermission';
export const AreaCrudPermissions = 'addorders/area_crudPermission';
export const ZoneCrudPermissions = 'addorders/zone_crudPermission';
export const ShipperCrudPermissions = 'addorders/shipper_crudPermission';
export const UserCrudPermissions = 'addorders/user_crudPermission';

export const ModelCrudPermissions = 'customization/model_crudPermission';

// hold reason
export const HoldReason = 'addorders/holdreson';

//  shipper swap permissions
export const GetShipperSwapPermissionMethodAPIUrl =
  'custompermissions/getshipperswapperm';
export const SaveShipperSwapPermission =
  'custompermissions/saveshipperswappermission';

// customer new password

export const CreatePasswordForCustomer =
  'custompermissions/create_customer_password';
export const UpdatePasswordForCustomer =
  'custompermissions/update_customer_password';
export const RemovePasswordForCustomer =
  'custompermissions/remove_customer_password';
export const CheckCustomerHasPasswordForCustomer =
  'custompermissions/check_customer_has_password';
export const CheckCustomerPasswordForCustomer =
  'custompermissions/check_customer_password';

// Database Apis

export const RestoreBackupDatabase = 'customization/restore_database';
export const BackupDatabase = 'customization/backup_db';
export const RestartService = 'customization/restart_service_cmd';
export const StopService = 'customization/stop_service_cmd';
export const GetBackupsDatabase = 'customization/get_backups';
export const DeleteBackupsDatabase = 'customization/delete_backup';
export const BulkDeleteBackupsDatabase = 'customization/bulk_delete_backup';
export const DeleteBackupsBeforeDatabase = 'customization/delete_backup_before';

export const getAllUserCustomerPermissionMethodURL =
  'custompermissions/getAllUserCustomerPermission';
export const getAllUserShipperPermissionMethodURL =
  'custompermissions/getAllUserShipperPermission';

export const getDuplicatedPermissionTypeMethodURL =
  'custompermissions/getDuplicatedPermissionType';
export const DuplicatedPermissionTypeMethodURL =
  'custompermissions/duplicatedPermissionType';

export const getUserRequestPermissionsMethodURL =
  'customization/get_user_request_permissions';

export const getCustomerPickupsMethodURL = 'customers/getCustomerPickups';
export const getCustomerCollectMethodURL = 'customers/getCustomerCollect';

export const getCustomerMaterialRequestMethodURL =
  'customers/getCustomerMaterialRequest';

export const getCustomerOrdersCityReportMethodURL =
  'customization/get_customer_orders_count_by_city';
export const getCustomerOrdersCityDetailsReportMethodURL =
  'customization/get_customer_city_details';
export const getCustomerOrdersCityFiltersMethodURL =
  'customization/getCustomerReportFilterList';

export const GetCustomerHistoryMethodUrl = 'customization/get_customer_history';
export const GetShipperHistoryMethodUrl = 'customization/get_shipper_history';

export const ReturnPriceListMethodURL = 'customization/return_price/';

export const MaterialCreatetApiUrl = 'stock/material-create-api';
export const MaterialListApiUrl = 'stock/material-list-api';
export const MaterialDetailstApiUrl = 'stock/material-details-api';
export const MaterialUpdateApiUrl = 'stock/material-update-api';
export const MaterialDeletetApiUrl = 'stock/material-delete-api';
export const MaterialBulkDelete = 'stock/material-bulk-delete';

export const WarehouseCreatetApiUrl = 'stock/warehouse-create-api';
export const WarehouselListApiUrl = 'stock/warehouse-list-api';
export const WarehouseApiUrl = 'stock/warehouse-list-api';
export const WarehouseDetailstApiUrl = 'stock/warehouse-details-api';
export const WarehouseUpdateApiUrl = 'stock/warehouse-update-api';
export const WarehouseDeletetApiUrl = 'stock/warehouse-delete-api';
export const WarehouseBulkDelete = 'stock/warehouse-bulk-delete';

export const StockEntryCreatetApiUrl = 'stock/stock-entry-create-api';
export const StockEntryListApiUrl = 'stock/stock-entry-list-api';
export const StockEntryDetailstApiUrl = 'stock/stock-entry-details-api';
export const StockEntryUpdateApiUrl = 'stock/stock-entry-update-api';
export const StockEntryDeletetApiUrl = 'stock/stock-entry-delete-api';
//delete-stock-entry
export const StockEntryBulkDeletetApiUrl = 'stock/delete-stock-entry';

export const StockLedgerListApiUrl = 'stock/stock-ledger-list-api';

export const CustomerMaterialBalanceListApiUrl =
  'stock/get_customer_material_balance';
export const StockBinListApiUrl = 'stock/bin-list-api';

export const ReturnRequestListMethodURL =
  'customization/get_return_request_list';
export const ReturnRequestListDetailsMethodURL =
  'customization/get_return_request_details';
export const UnactiveCustomerReportDetailsMethodURL =
  'customization/get_unactive_customers_report';
export const ActiveCustomersMethodURL = 'customization/active_customers';

export const ChangeMaterialRequestShipper =
  'addorders/change_material_request_shipper';
export const ChangeCollectRequestShipper =
  'addorders/change_collect_request_shipper';
export const ChangePickupRequestShipper =
  'addorders/change_pickup_request_shipper';
export const GetTodayShipperOrder = 'addorders/get_shipper_orders';
// bin list
export const BinListApiUrl = 'stock/bin-list-api';

export const GetReturnShipperOrder = 'addorders/get_return_shipper_orders';

// CustomerMaterialLimtiApi

export const CustomerMaterialLimtiApi = 'stock/customerMaterialLimit';

// CustomerMaterialDifferenceApi

export const CustomerMaterialDifferenceApi =
  'stock/stock-entry-details-difference-list-api';

export const getAccessToken = () => {
  let token = localStorage.getItem('Token');
  if (!token) window.location.reload();
  return token;
};

export const fetch_instance = async (url, options = {}) => {
  // Default options are marked with *

  return fetch(url, options).then((response) => {
    if (response.status == 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
    return response;
  });
};
