import toast from "react-hot-toast";
import wait from "../utils/wait";
import {
  ServerURL,
  ShipperMethodURL,
  getAccessToken,
  fetch_instance,
  UpdateShipperMethodURL,
  GetShipperMethodURL,
  DeleteShipperMethodURL,
  ShipperFormListMethodURL,
  ShipperLocationMethodURL,
  GoogleApiKey,
  UpdateShipperToShipperMethodURL,
  CreateShipperMethodURL,
  getAllUserShipperPermissionMethodURL,
  GetShipperHistoryMethodUrl,
  DailySendWhatsappShipperMethodURL,
} from "./ServerConfig";

class shipper_api {

  async get_shipper_history(shipper_id) {
    let result = [];
    await fetch_instance(
      `${ServerURL}${GetShipperHistoryMethodUrl}?id=${shipper_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data)
        result = data;
        return Promise.resolve(result);
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(result);
  }
  async get_form_list() {
    let obj = [];
    await fetch_instance(`${ServerURL}${ShipperFormListMethodURL}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        if (data.cities) {
          let cities = [];
          data.cities.forEach((e) => {
            cities.push({
              text: e.name,
              value: e.id,
            });
          });
          data.cities = cities;
        }
        // return Promise.resolve(obj)
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(obj);
  }
  async get_Shipper_list() {
    let obj = [];
    await fetch_instance(`${ServerURL}${ShipperMethodURL}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        // return Promise.resolve(obj)
      })
      .catch((error) => {
        toast.error(error.toString());
        console.log(error);
      });
    return Promise.resolve(obj);
  }
  async get_Shipper(id) {
    let obj = {};
    await fetch_instance(`${ServerURL}${GetShipperMethodURL}?id=${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        // return Promise.resolve(obj)
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
  async update_Shipper(obj) {
    let result = {};

    await fetch_instance(`${ServerURL}${UpdateShipperMethodURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      result = response;
      // return Promise.resolve(result)
    });
    return Promise.resolve(result);
  }
  async daily_send_orders_to_shipper(obj={}) {
    let result = {};

    await fetch_instance(`${ServerURL}${DailySendWhatsappShipperMethodURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      result = response;
      // return Promise.resolve(result)
    });
    return Promise.resolve(result);
  }

  
  async change_shipper_data_to_shipper(obj) {
    let result = {};

    await fetch_instance(`${ServerURL}${UpdateShipperToShipperMethodURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      result = response;
      // return Promise.resolve(result)
    });
    return Promise.resolve(result);
  }

  async new_Shipper(obj) {
    let result = {};

    await fetch_instance(`${ServerURL}${CreateShipperMethodURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(obj),
    }).then((response) => {
      // console.log(response)
      result = response;
      // return Promise.resolve(result)
    });
    return Promise.resolve(result);
  }
  async delete_Shipper(id) {
    await fetch_instance(`${ServerURL}${DeleteShipperMethodURL}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({
        id: id,
      }),
    }).then((response) => {
      // console.log(response)
    });
    // .catch((error)=>{
    //     toast.error(error.toString())
    // console.log(error);
    // });
  }
  async get_Shipper_locations(date, shipper) {
    let obj = [];
    await fetch_instance(
      `${ServerURL}${ShipperLocationMethodURL}?day_date=${date}&shipper_id=&shipper_id=${
        shipper || ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then(async (data) => {
        obj = await this.getDataAddress(data);
        // obj=data
        return Promise.resolve(obj);
      })
      .catch((error) => {
        // toast.error(error.toString());
        console.log(error);
      });
    // console.log("arrrrrrrrrrrrrrrrrrrrrrrrrrrrr", obj);
    return obj;
  }

  async getMapAddress(row) {
    let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${row.lat},${row.lng}&key=${GoogleApiKey}`;
    let address = "";
    await fetch_instance(url)
      .then((response) => response.json())
      .then((obj) => {
        if (obj.results && obj.results.length > 0) {
          address = obj.results[0].formatted_address || "";
          return Promise.resolve(address);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return Promise.resolve(address);
  }
  async getDataAddress(data) {
    console.log("data", data);

    data.forEach(async (row) => {
      row.address = await this.getMapAddress(row);
    });
    await wait(500);
    return Promise.resolve(data);
  }

  async get_defualt_shipper_with_order_id(id) {
    let obj = {};
    await fetch_instance(
      `${ServerURL}customization/get_defualt_shipper_with_order_id?id=${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        obj = data;
        // return Promise.resolve(obj)
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }

  async getAllUserShipperPermission(shippers = [], user = "") {
    let obj = {};
    // let parameters = new URLSearchParams(params);

    let shippers_params = "";
    if (shippers.length > 0) {
      shippers_params = encodeURIComponent(JSON.stringify(shippers || []));
    }
    let parameters = `&shippers=${shippers_params || []}&user=${user || ''}`;
    await fetch_instance(
      `${ServerURL}${getAllUserShipperPermissionMethodURL}?${parameters}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        obj = result;
        return Promise.resolve(obj);
      })
      .catch((error) => {
        toast.error(error.toString());
      });
    return Promise.resolve(obj);
  }
}
export const ShipperApi = new shipper_api();
